import React from "react";
import {
  Box,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Text,
  Button,
  useColorModeValue,
  useStyleConfig
} from "@chakra-ui/react";
import routes from "../../routes";
import Navbar from "../../components/Navbar/Navbar";
import contactimg from "../../assets/contact.png";
import contactimg1 from "../../assets/contact1.png";
import { AiFillPhone, AiOutlineMail ,AiOutlineLinkedin,AiOutlineGithub} from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Contact() {
  const styles = useStyleConfig("Card");
  const textColorPrimary = useColorModeValue("lightblue.100", "lightpeach.100");
  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };
  return (
    <Box fontWeight='bold' justifyContent='center' alignItems='center' justifyItems='center' textAlign='center' marginTop='10%'>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt="30px">
        {/* <Flex justifyContent="center" alignItems="center">
          <Box>
            <Image src={contactimg}></Image>
          </Box>
          <Box>
            <Image src={contactimg1}></Image>
          </Box>
        </Flex> */}

        <SimpleGrid
          columns={{ base: 1, xl: 3, "2xl": 3 }}
          gap="20px"
          mt={{ sm: "20px", xl: "0" }}
          mb="20px"
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}
        >
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box __css={styles}>
              <Box textAlign="center">
                <Icon
                  as={FaLocationDot}
                  width="30px"
                  height="30px"
                  color="inherit"
                />
              </Box>
              <Text textAlign="center">Currently Working at</Text>
              <Text color={textColorPrimary} textAlign="center" mt="5px">
                Unicorn Logics
              </Text>
            </Box>
          </Flex>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box __css={styles}>
              <Box textAlign="center">
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:m.zain.amir.92@gmail.com";
                    e.preventDefault();
                  }}
                >
                  <Icon
                    as={AiOutlineMail}
                    width="30px"
                    height="30px"
                    color="inherit"
                    cursor="pointer"
                  />
                </Link>
              </Box>

              <Text textAlign="center">EMAIL ME </Text>

              <Text color={textColorPrimary} textAlign="center" mt="5px">
                m.zain.amir.92@gmail.com
              </Text>
            </Box>
          </Flex>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box __css={styles}>
              <Box textAlign="center">
                <Icon
                  as={AiFillPhone}
                  width="30px"
                  height="30px"
                  color="inherit"
                />
              </Box>
              <Text textAlign="center">CALL ME</Text>
              <Text color={textColorPrimary} textAlign="center" mt="5px">
                +92 305 5452705
              </Text>
            </Box>
          </Flex>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box __css={styles}>
              <Box textAlign="center">
                <Icon
                  as={AiOutlineLinkedin}
                  width="30px"
                  height="30px"
                  color="inherit"
                />
              </Box>
              <Text textAlign="center">Reach me on LinkedIn</Text>
              <Text color={textColorPrimary} textAlign="center" mt="5px">
              <Link
                href='https://linkedin.com'
                target="blank"
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px"
                }}
              >
                <Button
                  variant="darkBrand"
                  fontSize="sm"
                  fontFamily="DM Sans"
                  borderRadius="70px"
                  px="24px"
                  py="5px"
                >
                  Visit LinkedIn
                </Button>
              </Link>
              </Text>
            </Box>
          </Flex>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Box __css={styles}>
              <Box textAlign="center">
                <Icon
                  as={AiOutlineGithub}
                  width="25px"
                  height="25px"
                  color="inherit"
                />
              </Box>
              <Text textAlign="center">Follow me on Github</Text>
              <Text color={textColorPrimary} textAlign="center" mt="5px">
              <Link
                href='https://github.com/zain-amir'
                target="blank"
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px"
                }}
              >
                <Button
                  variant="darkBrand"
                  fontSize="sm"
                  fontFamily="DM Sans"
                  borderRadius="70px"
                  px="24px"
                  py="5px"
                >
                  Follow
                </Button>
              </Link>
              </Text>
            </Box>
          </Flex>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
