import React from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Grid,
  useStyleConfig,
  Text,
  useColorModeValue,
  useColorMode,
  Icon,
  Button,
  Link
} from "@chakra-ui/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { MdFlightTakeoff } from "react-icons/md";
import { LiaDrumSolid } from "react-icons/lia";
import { GiCricketBat } from "react-icons/gi";
import Navbar from "../../components/Navbar/Navbar";
import routes from "../../routes";
import TechStackCard from "../../components/techStackCard/techStackCard";
import AboutMeInfo from "../../components/aboutMeInfo/aboutMeInfo";
import angular from "../../assets/icons/angular.svg";
import typescript from "../../assets/icons/typescript.svg";
import node from "../../assets/icons/nodejs.svg";
import mongo from "../../assets/icons/mongodb.svg";
import js from "../../assets/icons/javascript.svg";
import react from "../../assets/icons/react.svg";
import html from "../../assets/icons/html.svg";
import css from "../../assets/icons/css3.svg";
import git from "../../assets/icons/git.svg";
import cpp from "../../assets/icons/c++.png";
import three from '../../assets/icons/three.png'
import express from "../../assets/icons/express.svg";
import reactNative from "../../assets/icons/reactNative.png";
import flutter from "../../assets/icons/flutter.png";
// import express from "../../assets/icons/express.svg";
// import {TypeAnimation}

export default function About() {
  const styles = useStyleConfig("Card");
  const { colorMode } = useColorMode();
  const textColorPrimary = useColorModeValue("orange.400", "orange.400");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box  fontFamily='Ubuntu' fontWeight='bold' alignItems="center"
    justifyContent="center" textAlign='center' alignContent='center' justifyItems='center'>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box textAlign='center' pt={{ base: "40px", md: "80px", xl: "80px" }}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          {/* <TypeAnimation
            sequence={["ABOUT ME", 500, "ABOUT", 500, "ABOUT ME", 500]}
            style={{ fontSize: "3em" }}
            cursor={false}
          /> */}
          <Grid
            templateColumns={{
              base: "1fr",
              lg: "1.34fr 1.62fr"
            }}
            templateRows={{
              lg: "1fr"
            }}
            gap={{ base: "20px", xl: "20px" }}
          >
            
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              width='100%'
            >
              <Box __css={styles} border="none" bg="transparent" alignItems="center"
                justifyContent="center" textAlign='center'>
                <Text
                  color={textColorPrimary}
                  fontSize="2xl"
                  mb="40px"
                  textAlign="center"
                >
                  {"<Know more bout me/>"}
                </Text>
                <SimpleGrid columns="2" gap="20px">
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Currently @"
                    value="UnicornLogic"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Designation"
                    value="Junior software developer"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Location"
                    value="Lahore, PK"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Academics"
                    value="BSCS, PUCIT"
                  />
                  {/* <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Degree"
                    value="Masters"
                  /> */}
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Languages"
                    value="English , Urdu , Punjabi"
                  />
                </SimpleGrid>
              </Box>
            </Flex>
          </Grid>
        </Flex>

        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="1.5em" textAlign="center">
            {"<MY SKILLSET/>"}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ base: 2, md: 2, lg: 3, xl: 6, "2xl": 6 }}
          gap="20px"
          mb="20px"
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}
        >
          <TechStackCard imagepath={js} />
          
          <TechStackCard imagepath={react} />
          <TechStackCard imagepath={reactNative} />
          <TechStackCard imagepath={flutter} />
          
          
          <TechStackCard imagepath={typescript} />
          {/* <TechStackCard imagepath={angular} /> */}
          <TechStackCard imagepath={mongo} />
          <TechStackCard imagepath={node} />
          <TechStackCard imagepath={express} background="white" />
          {/* <TechStackCard imagepath={gitlab} /> */}
          <TechStackCard imagepath={three}  />
          
          <TechStackCard imagepath={cpp} />
          <TechStackCard imagepath={git} />
          <TechStackCard imagepath={html} />
          <TechStackCard imagepath={css} />
        </SimpleGrid>

      
       
        <Flex justifyContent="center" alignItems="center" mt="20px">
          <Link href="https://github.com/zain-amir" target="blank">
            <Button variant="darkBrand" fontSize="sm" fontFamily="DM Sans">
              Go to Github
            </Button>
          </Link>
        </Flex>
        
      </Box>
    </Box>
  );
}
