import React from "react";
import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import routes from "../../routes";
import Navbar from "../../components/Navbar/Navbar";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import greenhouse from '../../assets/projects/greenHouse.png'
import FreshTracked from '../../assets/projects/freshTracked.png'
import khanapeena from '../../assets/projects/khanapeena.png'
import greystudios from '../../assets/projects/greystudios.png'
import leep from '../../assets/projects/leep.png'
import pillpal from '../../assets/projects/pillpal.png'
import tomtailor from '../../assets/projects/tomtailor.png'
import appfest from '../../assets/projects/appfest.jpg'


export default function Projects() {
  const textColorPrimary = useColorModeValue(
    "secondaryGray.900",
    "lightpeach.100"
  );
  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "20px", md: "40px", xl: "40px" }}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        ></Flex>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap="25px"
          alignItems="center"
          justifyContent="center"
        >
          <ProjectCard
            projectImage={tomtailor}
            projectName={"Tom Tailor Concept Store"}
            aboutProject={"Developed a concept store website for Tom Tailor"}
            techStack={"Node, React, Node, Express, Gsap, ThreeJs, TailwindsCSS"}
            projectLiveLink="https://tom-tailor-concept.vercel.app/"
            projectLink="https://tom-tailor-concept.vercel.app/"
          />
          <ProjectCard
            projectImage={khanapeena}
            projectName={"KhanaPeena.Pk"}
            aboutProject={"A platform where you find your best Buffets!"}
            techStack={"EXPRESS JS, NODE JS, REACT JS, MONGODB"}
            projectLiveLink="https://khanapeena.pk"
            projectLink=""
          />
          <ProjectCard
            projectImage={FreshTracked}
            projectName={"Fresh Tracked"}
            aboutProject={"An app which helps you track your aligners and make your dental journey easy."}
            techStack={"Flutter, Firebase"}
            projectLiveLink="https://play.google.com/store/apps/details?id=com.retainers.FreshTracked"
            projectLink=""
          />
          <ProjectCard
            projectImage={greenhouse}
            projectName={"GreenHouse App "}
            aboutProject={"A social media app where people with similar intrests can meet and play fun games. With live events and much more! "}
            techStack={"Flutter,NodeJs, Mongo"}
            projectLiveLink="https://github.com/Zain-Amir/GreenHouseApp"
            projectLink="https://github.com/Zain-Amir/GreenHouseApp"
          />
          <ProjectCard
            projectImage={greystudios}
            projectName={"Grey Studios AdminDashboard "}
            aboutProject={
              "A practice project involving admin dashboard for GreyStudios. "
            }
            techStack={
              "React, Node, Tailwind, NodeJs"
            }
            projectLiveLink="https://gray-studios-reacr-admin-dashboard.vercel.app/"
            projectLink="https://github.com/Zain-Amir/GrayStudios-reacr-admin-Dashboard"
          />
          <ProjectCard
            projectImage={khanapeena}
            projectName={"NLP based Movie Recommendation System"}
            aboutProject={"An NLP based movie recommedndation system which had a data set of 25k+ movies and recommended movies with efficiecy"}
            techStack={"Python, scikit, Jupyter, Streamlit"}
            projectLiveLink="https://github.com/Zain-Amir/ML_movie_recommender_system/"
            projectLink="https://github.com/Zain-Amir/ML_movie_recommender_system/"
          />
          <ProjectCard
            projectImage={leep}
            projectName={"Leep - FYP"}
            aboutProject={
              "A crowd-funding platform where bright minds can make their dreams come true, by the people for the people!"
            }
            techStack={
              "React, Node, MongoDb, Express, Socket.io, Stripe"
            }
            projectLink="https://leep-three.vercel.app/"
          />
          <ProjectCard
            projectImage={pillpal}
            projectName={"Pill-Pal"}
            aboutProject={
              "in-progress : Your pill buddy, helps you take meds on time with ability to allow your loved ones to keep track of your progress"
            }
            techStack={"React Native, tailwindcss, Firebase, SQllite"}
            projectLink="https://www.figma.com/file/0jXq0zRV68KIimswpjX0Bq/PillPal?type=design&node-id=0%3A1&mode=design&t=5v1wr37Y3cBs1FU6-1https://github.com/Rahul1582/Bloggers-Arena"
          />
        </SimpleGrid>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="Center"
          mt="40px"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<Achievements/>"}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap="50px"
          alignItems="center"
          justifyContent="center"
        >
          <ProjectCard
            projectImage={appfest}
            projectName={"AppFest Winner"}
            aboutProject={"Won the app fest competition, by creating a Quran tranlation app from scratch in 8 hours with 2 members.  "}
            projectLink="https://pucit.edu.pk/app-fest-1-0-winners/"
          />
          <ProjectCard
          projectImage={appfest}
          projectName={"AppFest Winner"}
          aboutProject={"Won the app fest competition, by creating a Quran tranlation app from scratch in 8 hours with 2 members.  "}
          projectLink="https://pucit.edu.pk/app-fest-1-0-winners/"
        />
        </SimpleGrid>
      </Box>
    </Box>
  );
}
