import React from "react";
import {
  Flex,
  Box,
  Text,
  Grid,
  useColorModeValue,
  useStyleConfig
} from "@chakra-ui/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../components/Navbar/Navbar";
import routes from "../../routes";
import dashboardimg from "../../assets/dashboard.gif";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Typewriter from "typewriter-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import {Lottie} from 'lottie';
import Lottie from "lottie-react";
import animationData from '../../assets/icons/rocket.json'; // Replace with the path to your Lottie animation JSON file

export default function Dashboard() {
  const [quote, setQuote] = useState(" Web and Mobile Apps ");
  const styles = useStyleConfig("Card");

  let highlightTextColor = useColorModeValue("orange.400", "orange.400");
  let textColor = useColorModeValue("gray.700", "white");

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "100px", md: "80px", xl: "10px" }}>
        <Flex direction="row" justifyContent="center" alignItems="center">
          <Grid
            templateColumns={{
              base: "1fr",
              lg: "1fr 1fr"
            }}
            templateRows={{
              base: "repeat(2, 0.5fr)",
              lg: "1fr"
            }}
            gap={{ xl: "20px" }}
          >
            <Box
              pt={{ xl: "100px" }}
              pl="10px"
              textAlign="center"
              fontSize={{ sm: "1.1em", md: "2em", xl: "2.5em" }}
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box __css={styles} border="none">
                  <Text
                    fontFamily="Ubuntu"
                    fontWeight="medium"
                    color={textColor}
                  >
                    Hey! 
                  </Text>

                  <Text
                    fontFamily="Ubuntu"
                    fontWeight="medium"
                    color={textColor}
                    mt="20px"
                  >
                    This is{" "}
                    <Text display="inline-flex" color={highlightTextColor}>
                      Zain
                    </Text>
                  </Text>

                  <Text
                    fontFamily="Ubuntu"
                    fontWeight="medium"
                    color={textColor}
                    mt="1px"
                  >
                    I develop cool <br></br>
                    <Text display="inline-flex" color={highlightTextColor}>
                    {quote ? (
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.typeString(quote).start();
                  }}
                />
              ) : (
                <span>|</span>
              )}
                    </Text>
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
               <div>
                <Lottie animationData={animationData} autoplay loop />
              </div>
            </Flex>
          </Grid>
        </Flex>

       
      </Box>
    </Box>
  );
}
